<template>
  <div>
    <jaya-connection-layout text="Renseignez votre Email pour obtenir un lien de réinitialisation de votre mot de passe">
      <validation-observer v-slot="{invalid, handleSubmit}">
        <b-form @submit.prevent="handleSubmit(resetPassword)">
          <b-row>
            <!-- Email -->
            <b-col cols="12">
              <b-form-group label="Email">
                <ValidationProvider v-slot="{ errors }" rules="required|email">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon"/>
                    </b-input-group-prepend>

                    <b-form-input v-model="email"
                        autofocus
                        placeholder="Email"
                        type="email"/>
                  </b-input-group>
                  <p class="text-danger">{{ errors[0] }}</p>
                  <p v-if="error === 'userNotFound' "
                      class="text-danger">
                    L'utilisateur est inconnu
                  </p>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <!-- Buttons-->
            <b-col class="d-flex justify-content-around flex-wrap mb-1" cols="12">
              <b-button class="m-25 flex-grow-1" to="/login" variant="outline-primary">
                Retour
              </b-button>
              <b-button
                  :disabled="invalid"
                  class="m-25 flex-grow-1"
                  type="submit"
                  variant="primary">
                Envoyer le lien de réinitialisation
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </jaya-connection-layout>

    <b-modal
        v-model="popUpMoreInfoCheck"
        hide-footer
        modal-class="modal-primary"
        title="Compte inactif !">
      <p> Il semblerait que votre compte ne soit pas activé. Le mail de validation s'est peut-être caché dans vos
        spams.</p>
      <p> Si vous ne le retrouvez pas, <span class="cursor-pointer text-primary"
          @click="resendEmail"> cliquez ici </span> pour un nouvel envoi
        (l'envoi peut prendre jusqu'à quelques minutes).</p>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BRow,
} from "bootstrap-vue"
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {email, required} from 'vee-validate/dist/rules'

extend('required', {...required, message: 'Ce champ est requis'});
extend('email', {...email, message: 'Email invalide'});

export default {
  components: {
    JayaConnectionLayout,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: "",
      popUpMoreInfo: false,
      error: "",
      activePopUp: true
    };
  },
  computed: {
    popUpMoreInfoCheck() {
      return this.$store.state.account.userIsInactive
    }
  },
  methods: {
    resetPassword() {
      let obj = {login: this.email};
      this.$store.dispatch("account/send_reset_password", obj)
          .then(() => {
            this.error = ""
            this.$notify(
                {
                  title: "Envoi effectué",
                  text: "Un email pour réinitialiser votre mot de passe vous a été envoyé à l'adresse : " + this.email,
                  time: 4000,
                  color: "primary"
                }
            )
          })
          .catch((error) => {
            this.$notify({
              time: 4000,
              title: this.$t('Error'),
              text: "L'envoi du lien de réinitialisation a échoué",
              color: "danger"
            })
            if (error.response && error.response.data.detail === "User not found") {
              this.error = "userNotFound"
            }
          });
    },
    resendEmail() {
      let payload = {email: this.email}
      this.$store.dispatch("account/resend_validation_email", payload)
          .then(() => {
            this.$notify(
                {
                  title: "Renvoi effectué",
                  text: "Un email vous a été renvoyé à l'adresse : " + this.email,
                  time: 4000,
                  color: "primary"
                }
            )
          })
          .catch(() => {
            this.$notify(
                {
                  title: this.$t('Error'),
                  text: "l'envoi du mail de validation a échoué",
                  time: 4000,
                  color: "danger"
                }
            )
      })
    },
  },
};
</script>

<style lang="scss">
</style>
